$(document).ready(function () {
	const switcher = $('[data-language-switcher]');
	const button = $('[data-language-switcher-button]');
	const items = $('[data-language-switcher-items]');
	const item = items.find('[data-language]');
	const active = $('[data-language-switcher-active] span');
	let language = '';

	const updateLanguage = () => {
		const currentUrl = window.location.pathname;
		const isMandarin = currentUrl.startsWith('/cn/');

		active.text(isMandarin ? '中文' : 'English');

		updateLinks(isMandarin);
	};

	const updateLinks = (isMandarin) => {
		$('a[href]').not('.c-footer a, #hpBanner a').each(function () {
			const href = $(this).attr('href');
			const baseHref = href.replace(/^\/cn\//, '/');
			const newHref = isMandarin ? `/cn${baseHref}` : baseHref;
			$(this).attr('href', newHref);
		});
	};

	updateLanguage();

	button.on('click', (event) => {
		event.stopPropagation();
		switcher.toggleClass('--is-toggled');
	});

	items.on('click', (event) => {
		event.stopPropagation();
	});

	item.on('click', function () {
		const language = $(this).data('language');
		const currentUrl = window.location.pathname.replace(/^\/cn\//, '/');
		const newUrl = language === 'mandarin' ? `/cn${currentUrl}` : currentUrl;

		window.location.href = newUrl;

		switcher.removeClass('--is-toggled');
	});
});
